import gql from "graphql-tag";

/**
 * Update Cart
 *
 * This query is used for both updating the items in the cart and delete a cart item.
 * When the cart item needs to be deleted, we should pass quantity as 0 in the input along with other fields.
 */
const UPDATE_CART = gql`
mutation ($input: UpdateItemQuantitiesInput!) {
  updateItemQuantities(input: $input) {
    items {
      key
      product {
        node {
          databaseId
          description
          id
          image {
            altText
            id
            guid
          }
          name
          shortDescription
          slug
          type
          galleryImages {
            nodes {
              altText
              id
              guid
            }
          }
        }
      }
      variation {
        node {
          databaseId
          description
          image {
            altText
            databaseId
            guid
            id
          }
          id
          name
          price
          regularPrice
          salePrice
          onSale
          type
          attributes {
            nodes {
              id
              name
              value
            }
          }
        }
      }
      quantity
      total
      subtotal
      subtotalTax
    }
    removed {
      key
      product {
        node {
          databaseId
          id
        }
      }
      variation {
        node {
          databaseId
          id
        }
      }
    }
    updated {
      key
      product {
        node {
          databaseId
          id
        }
      }
      variation {
        node {
          id
          databaseId
        }
      }
    }
  }
}
`;

export default UPDATE_CART;

/* eslint-disable react/react-in-jsx-scope */

const StaticFooter = () => {


	return (
		<div className="footer-text flex-col lg:flex-row flex lg:text-left justify-between">
			<div className="text-black mr-2 mt-5 lg:mt-0"><span className="font-bold text-sm font-helvetica-text font-normal">Empresa</span>
				<div className="social-links mt-2 align-center">
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/nuestra-historia/">Nuestra Historia</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/nuestros-almacenes/">Nuestros Almacenes</a>
					</div>
				</div>
			</div>
			<div className="text-black mr-2 mt-5 lg:mt-0">
				<span className="font-bold text-sm font-helvetica-text font-normal">Ayuda</span>
				<div className="social-links mt-2 align-center">
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/cambios-devoluciones/">Cambios &amp; Devoluciones</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/preguntas-frecuentes/">Preguntas Frecuentes</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/derecho-de-retracto/">Derecho de Retracto</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/garantias/">Garantías</a>
					</div>
				</div>
			</div>
			<div className="text-black mr-2 mt-5 lg:mt-0">
				<span className="font-bold text-sm font-helvetica-text font-normal">Fidelización</span>
				<div className="social-links mt-2 align-center">
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/como-funciona/">Cómo Funciona</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" href="/iniciar-sesion/">Regístrate</a>
					</div>
				</div>
			</div>
			<div className="text-black mr-2 mt-5 lg:mt-0">
				<span className="font-bold text-sm font-helvetica-text font-normal">Social</span>
				<div className="social-links mt-2 align-center">
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" rel="noreferrer" target="_blank" href="https://www.facebook.com/classicjeansco">Facebook</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" rel="noreferrer" target="_blank" href="https://www.instagram.com/classicjeansco">Instagram</a>
					</div>
					<div className="mt-3">
						<a className="text-gray-darkest text-sm font-helvetica-text font-normal" rel="noreferrer" target="_blank" href="https://www.tiktok.com/classicjeansco/">TikTok</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StaticFooter;
